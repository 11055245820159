// ApesPage.js
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../SearchBar";
import NFTList from "../NFTList";
import AssetModal from "../AssetModal";
import Families from "./Families";
import "./ApesPage.css";
import { useWallet } from "../../contexts/WalletProvider";
import apesData from "../../Decrypted.json"; // Updated import
import calculateAge from "../../utils/calculateAge";
import assetsList from "../../constants/assetList";
import { CONTRACT_ADDRESS, CONTRACT_ABI } from "../../utils/contract";
import { ethers } from "ethers";

const ApesPage = () => {
  const navigate = useNavigate();
  const { walletAddress, provider } = useWallet(); // Destructure provider from context
  const [nfts, setNfts] = useState([]);
  const [userNfts, setUserNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("explore");
  const [selectedAssets, setSelectedAssets] = useState({});
  const [selectedFaction, setSelectedFaction] = useState(null);
  const [sortBy, setSortBy] = useState("All");
  const [isFactionDropdownOpen, setIsFactionDropdownOpen] = useState(false);
  const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const nftsPerPage = 40;
  const [searchTerm, setSearchTerm] = useState("");

  const factionDropdownRef = useRef(null);
  const assetDropdownRef = useRef(null);
  const observer = useRef();
  const lastNFTElementRef = useRef();

  // Cache for user NFTs to prevent redundant blockchain calls
  const userNftsCache = useRef({});

  // State for faction filter in Profile Tab
  const [profileSelectedFaction, setProfileSelectedFaction] = useState(null);

  const handleClickOutside = (event) => {
    if (
      factionDropdownRef.current &&
      !factionDropdownRef.current.contains(event.target)
    ) {
      setIsFactionDropdownOpen(false);
    }
    if (
      assetDropdownRef.current &&
      !assetDropdownRef.current.contains(event.target)
    ) {
      setIsAssetDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getFilteredAndSortedNFTs = useMemo(() => {
    let data = apesData;

    if (selectedFaction) {
      data = data.filter(
        (ape) => ape.faction.toLowerCase() === selectedFaction.toLowerCase()
      );
    }

    if (Object.keys(selectedAssets).length > 0) {
      data = data.filter((ape) =>
        Object.entries(selectedAssets).every(([traitType, value]) =>
          ape.attributes.some(
            (attr) =>
              attr.trait_type.toLowerCase() === traitType.toLowerCase() &&
              attr.value.toLowerCase() === value.toLowerCase()
          )
        )
      );
    }

    if (searchTerm && searchTerm.length > 0) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const results = data
        .filter((ape) => {
          const apeNameLower = ape.name.toLowerCase();
          return (
            apeNameLower.includes(lowerCaseSearchTerm) ||
            ape.id.toString() === lowerCaseSearchTerm
          );
        })
        .map((ape) => {
          const [firstName, ...rest] = ape.name.split(" ");
          const lastName = rest.join(" ");
          const firstNameLower = firstName.toLowerCase();
          const lastNameLower = lastName.toLowerCase();

          let priority = 2;

          if (firstNameLower.startsWith(lowerCaseSearchTerm)) {
            priority = 0;
          } else if (lastNameLower.startsWith(lowerCaseSearchTerm)) {
            priority = 1;
          }

          return { ...ape, priority };
        })
        .sort((a, b) => a.priority - b.priority);

      data = results;
    }

    return data;
  }, [selectedFaction, selectedAssets, searchTerm]);

  const loadNFTs = async () => {
    try {
      setLoadingMore(true);

      await new Promise((resolve) => setTimeout(resolve, 0));

      const offset = (page - 1) * nftsPerPage;
      let data = getFilteredAndSortedNFTs;

      const paginatedNfts = data
        .slice(offset, offset + nftsPerPage)
        .map((nft) => ({
          imageUrl: `https://storage.googleapis.com/apelantia/apelantians/${nft.id}.png`, // Updated image URL
          name: nft.name.replace(/ #\d+$/, ""), // Remove the #ID suffix
          id: nft.id,
          age: calculateAge(nft.birthdate),
          faction: nft.faction,
          role: nft.role,
        }));

      if (page === 1) {
        setNfts(paginatedNfts);
      } else {
        setNfts((prevNfts) => [...prevNfts, ...paginatedNfts]);
      }

      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      console.error(`Error loading NFTs: ${err.message}`);
      setError(`Error loading NFTs: ${err.message}`);
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setPage(1);
      setNfts([]);
      await loadNFTs();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFaction, selectedAssets, searchTerm]);

  useEffect(() => {
    if (page > 1) {
      loadNFTs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const dropdown = document.querySelector(".asset-items");

    if (dropdown) {
      const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dropdown.scrollTop += e.deltaY;
      };

      dropdown.addEventListener("wheel", preventScroll);

      return () => {
        dropdown.removeEventListener("wheel", preventScroll);
      };
    }
  }, [isAssetDropdownOpen]);

  const getFactionColor = (faction) => {
    switch (faction?.toLowerCase()) {
      case "mafia":
        return "darkred";
      case "rebel":
        return "green";
      case "royal":
        return "gold";
      case "genesis":
        return "purple"; // Added new faction color
      default:
        return "#EBEBEB";
    }
  };

  const handleFactionSortChange = (value) => {
    setLoading(true);
    setSelectedAssets({});
    setSelectedFaction(value === "All" ? null : value);
    setSortBy(value);
    setPage(1);
  };

  const handleOpenAssetModal = () => {
    setIsAssetModalOpen(true);
  };

  const handleCloseAssetModal = () => {
    setIsAssetModalOpen(false);
  };

  const hasMoreNFTs = nfts.length < getFilteredAndSortedNFTs.length;

  // Fetch user NFTs when profile tab is active
  useEffect(() => {
    const fetchUserNFTs = async () => {
      if (activeTab !== "profile" || !walletAddress || !provider) return;

      // Check if userNfts are already cached
      if (userNftsCache.current[walletAddress]) {
        setUserNfts(userNftsCache.current[walletAddress]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          CONTRACT_ABI,
          provider
        );
        const balance = await contract.balanceOf(walletAddress);
        const balanceNumber = balance.toNumber();

        if (balanceNumber === 0) {
          setUserNfts([]);
          userNftsCache.current[walletAddress] = [];
          setLoading(false);
          return;
        }

        // Fetch token IDs owned by the user
        const tokenIds = [];
        for (let i = 0; i < balanceNumber; i++) {
          const tokenId = await contract.tokenOfOwnerByIndex(walletAddress, i);
          tokenIds.push(tokenId.toNumber());
        }

        // Map token IDs to apesData
        const ownedApes = apesData.filter((ape) => tokenIds.includes(ape.id));

        // Process ownedApes similar to explore tab
        const processedOwnedApes = ownedApes.map((ape) => ({
          imageUrl: `https://storage.googleapis.com/apelantia/apelantians/${ape.id}.png`,
          name: ape.name.replace(/ #\d+$/, ""),
          id: ape.id,
          age: calculateAge(ape.birthdate),
          faction: ape.faction,
          role: ape.role,
        }));

        // Cache the fetched user NFTs
        userNftsCache.current[walletAddress] = processedOwnedApes;

        setUserNfts(processedOwnedApes);
        setLoading(false);
      } catch (err) {
        console.error(`Error fetching user NFTs: ${err.message}`);
        setError(`Error fetching your Apes: ${err.message}`);
        setLoading(false);
      }
    };

    fetchUserNFTs();
  }, [activeTab, walletAddress, provider]);

  // Filtered User NFTs based on selectedFaction in Profile Tab
  const filteredUserNfts = useMemo(() => {
    if (!profileSelectedFaction) return userNfts;
    return userNfts.filter(
      (ape) =>
        ape.faction.toLowerCase() === profileSelectedFaction.toLowerCase()
    );
  }, [userNfts, profileSelectedFaction]);

  useEffect(() => {
    if (loadingMore || loading) return;
    if (!hasMoreNFTs) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: "200px",
        threshold: 0,
      }
    );

    if (lastNFTElementRef.current) {
      observer.current.observe(lastNFTElementRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadingMore, loading, hasMoreNFTs, nfts]);

  if (error)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#202020",
          fontSize: "20px",
          fontFamily: "Poppins, monospace",
        }}
      >
        {error}
      </div>
    );

  const factionColor = getFactionColor(sortBy);

  return (
    <div className="apes-page-container">
      <div className="tab-navigation">
        <button
          className={`tab-button ${activeTab === "explore" ? "active" : ""}`}
          onClick={() => setActiveTab("explore")}
        >
          Explore
        </button>
        <button
          className={`tab-button ${activeTab === "families" ? "active" : ""}`}
          onClick={() => setActiveTab("families")}
        >
          Families
        </button>
        <button
          className={`tab-button ${activeTab === "profile" ? "active" : ""}`}
          onClick={() => setActiveTab("profile")}
        >
          Profile
        </button>
      </div>

      {activeTab === "explore" && (
        <div className="search-and-sort">
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <div className="faction-filters" ref={factionDropdownRef}>
            <div className="faction-select">
              <div
                className="faction-selected"
                onClick={() => setIsFactionDropdownOpen((prev) => !prev)}
                style={{ color: factionColor }}
              >
                {sortBy}
              </div>
              {isFactionDropdownOpen && (
                <div className="faction-items">
                  <div onClick={() => handleFactionSortChange("All")}>All</div>
                  <div
                    className="mafia-option"
                    onClick={() => handleFactionSortChange("Mafia")}
                  >
                    Mafia
                  </div>
                  <div
                    className="rebel-option"
                    onClick={() => handleFactionSortChange("Rebel")}
                  >
                    Rebel
                  </div>
                  <div
                    className="royal-option"
                    onClick={() => handleFactionSortChange("Royal")}
                  >
                    Royal
                  </div>
                  <div
                    className="genesis-option"
                    onClick={() => handleFactionSortChange("Genesis")}
                  >
                    Genesis
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="asset-filters" ref={assetDropdownRef}>
            <div className="asset-select" onClick={handleOpenAssetModal}>
              <div className="asset-selected">
                {Object.keys(selectedAssets).length > 0 || selectedFaction
                  ? `Selected (${
                      Object.keys(selectedAssets).length +
                      (selectedFaction ? 1 : 0)
                    })`
                  : "Select Assets"}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "profile" && (
        <div className="profile-filters">
          <div className="faction-buttons">
            {["All", "Mafia", "Rebel", "Royal"].map((faction) => (
              <button
                key={faction}
                className={`faction-button ${
                  profileSelectedFaction === faction ||
                  (faction === "All" && !profileSelectedFaction)
                    ? "active"
                    : profileSelectedFaction
                    ? "inactive"
                    : ""
                } ${faction.toLowerCase()}`}
                onClick={() =>
                  faction === "All"
                    ? setProfileSelectedFaction(null)
                    : setProfileSelectedFaction(faction)
                }
              >
                {faction}
              </button>
            ))}
          </div>
        </div>
      )}

      {activeTab === "explore" ? (
        <>
          {loading && page === 1 ? (
            <div className="loading-indicator">Loading...</div>
          ) : (
            <NFTList
              nftCardsData={nfts}
              lastNFTElementRef={lastNFTElementRef}
            />
          )}
          {(loadingMore || !hasMoreNFTs) && (
            <div className="loading-indicator">
              {loadingMore ? "Loading..." : "No more Apes to display"}
            </div>
          )}
        </>
      ) : activeTab === "families" ? (
        <Families />
      ) : activeTab === "profile" ? (
        <>
          {loading ? (
            <div className="loading-indicator">Loading your Apes...</div>
          ) : filteredUserNfts.length > 0 ? (
            <NFTList nftCardsData={filteredUserNfts} />
          ) : (
            <div className="no-apes-container">
              <div className="no-apes-message">You do not own any Apes</div>
              <button onClick={() => navigate("/")} className="MintButton2">
                Mint
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="no-apes-container">
          <div className="no-apes-message">You do not own any Apes</div>
          <button onClick={() => navigate("/")} className="MintButton2">
            Mint
          </button>
        </div>
      )}
      {isAssetModalOpen && (
        <AssetModal
          assetsList={assetsList}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
          selectedFaction={selectedFaction}
          setSelectedFaction={setSelectedFaction}
          onClose={handleCloseAssetModal}
          setSortBy={setSortBy}
        />
      )}
    </div>
  );
};

export default ApesPage;
