// FactionPage.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apesData from "../../Encrypted.json";
import FamilyCard from "../FamilyCard";
import "./FactionPage.css";

const FactionPage = () => {
  const { factionName } = useParams();
  const [families, setFamilies] = useState([]);

  useEffect(() => {
    // Group apes by family name, but only include those in the specified faction
    const familyMap = new Map();

    apesData.forEach((ape) => {
      if (ape.faction.toLowerCase() === factionName.toLowerCase()) {
        const nameParts = ape.name.split(" ");
        const lastName = nameParts[nameParts.length - 1];

        if (!familyMap.has(lastName)) {
          familyMap.set(lastName, []);
        }
        familyMap.get(lastName).push(ape);
      }
    });

    const familiesArray = Array.from(familyMap.entries()).map(
      ([familyName, members]) => {
        const familyFaction = members[0].faction;
        return {
          familyName,
          familyMembers: members,
          familyFaction,
        };
      }
    );

    setFamilies(familiesArray);
  }, [factionName]);

  if (families.length === 0) {
    return <div>No families found for faction {factionName}</div>;
  }

  return (
    <div className="faction-families-container">
      <h1 className="faction-page-title">{factionName} Families</h1>
      <div className="family-list">
        {families.map((family) => (
          <FamilyCard
            key={family.familyName}
            familyName={family.familyName}
            familyMembers={family.familyMembers}
            familyFaction={family.familyFaction}
          />
        ))}
      </div>
    </div>
  );
};

export default FactionPage;
