import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import DrawSvg from "../DrawSvg";
import Typewriter from "typewriter-effect";

// PIXELATED :

import RoyalBuilding from "../../assets/pixelatedBuildings/royalbuilding.webp";
import SecurityBuilding from "../../assets/pixelatedBuildings/security.webp";
import Amphitheater from "../../assets/pixelatedBuildings/amphi.webp";
import SecretService from "../../assets/pixelatedBuildings/secretservice.webp";
import BankOfApelantia from "../../assets/pixelatedBuildings/bankofapelantia.webp";
import EnergyFactory from "../../assets/pixelatedBuildings/energyfactory.webp";
import RoyalRestaurant from "../../assets/pixelatedBuildings/royalrestaurant.webp";
import CommissionHQ from "../../assets/pixelatedBuildings/commissionhq.webp";
import MafiaCafe from "../../assets/pixelatedBuildings/mafiacafe.webp";
import MilitaryGround from "../../assets/pixelatedBuildings/militaryground.webp";
import WeaponGuild from "../../assets/pixelatedBuildings/weaponsguild.webp";
import VIPHomes from "../../assets/pixelatedBuildings/vip.webp";
import TownHall from "../../assets/pixelatedBuildings/townhall.webp";
import RebelPub from "../../assets/pixelatedBuildings/rebelpub.webp";
import TheFarms from "../../assets/pixelatedBuildings/thefarms.webp";
import HealingTents from "../../assets/pixelatedBuildings/healingtent.webp";
import RuralSchool from "../../assets/pixelatedBuildings/ruralschool.webp";
import SnakeRiver from "../../assets/pixelatedBuildings/snake.webp";
import TwinTowers from "../../assets/pixelatedBuildings/twintowers.webp";
import AHouse from "../../assets/pixelatedBuildings/justahouse.webp";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 90%;
  height: 500vh;
  background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 95%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;

        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }

    p {
      border-radius: 40px 0 40px 0;
    }
  }

  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }

    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative; // Ensures absolute positioning of children like Image is relative to Item

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;

const ItemContainer = styled.div`
  width: 35%;
  height: fit-content; // Might need to adjust if you want the container to fit the height of the image
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};
  position: relative; // Necessary for positioning and stacking context
  z-index: 1; // Ensures that the content in ItemContainer is above the Image

  @media (max-width: 48em) {
    width: 75%;
  }
`;

const Box = styled.div`
  background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
  z-index: 2; // Ensures that the Box is above the Image
  overflow: hidden;

  @media (max-width: 48em) {
    padding: 2rem;
  }
`;

const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.color || props.theme.text};
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;

const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontlg};
  text-align: center;
  color: ${(props) => props.color || "#202020"};
  font-weight: 400;
  margin: 0.5rem 0;
  text-shadow: 1px 1px 0 #ebebeb, -1px -1px 0 #ebebeb, -1px 1px 0 #ebebeb,
    1px -1px 0 #ebebeb;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const Image = styled.img`
  width: 100%; // Ensures the image covers the full width of its container
  height: 100%; // Ensures the image covers the full height of its container
  object-fit: cover; // Ensures the image covers the area of the box, may clip the image
  position: absolute; // Positions the image absolutely within its parent
  top: 0; // Aligns the top of the image with the top of its container
  left: 0; // Aligns the left of the image with the left of its container
  z-index: -1; // Ensures the image stays behind the content if other elements are in the same container
  opacity: 0.75; // Sets the opacity of the image
  transition: opacity 0.3s ease; // Smooth transition for opacity changes
  filter: blur(1px); // Applies a blur effect to the image
`;

const LocationText = styled(Text)`
  text-shadow: 1px 1px 0 #202020, -1px -1px 0 #202020, -1px 1px 0 #202020,
    1px -1px 0 #202020;
`;

const RoadMapItem = ({ title, subtext, image, location, addToRef, onOpen }) => {
  let locationColor;
  switch (location) {
    case "Royal":
      locationColor = "#D4AF37"; // Gold
      break;
    case "Rebel":
      locationColor = "#009900"; // Bright Green
      break;
    case "Mafia":
      locationColor = "#D02020"; // Bright Red
      break;
    case "No Man's Land":
      locationColor = "#FFFFE6"; // Orange
      break;
    default:
      locationColor = "#EBEBEB"; // Default color when location doesn't match
  }

  return (
    <Item onClick={() => onOpen(title, subtext, image, location)}>
      <ItemContainer>
        <Box>
          <SubTitle color={locationColor}>{title}</SubTitle>
          <Image src={image} alt={title} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>{"Location:"}&nbsp;</Text>
            <LocationText color={locationColor}>{location}</LocationText>{" "}
          </div>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Roadmap = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const revealRefs = useRef([]);
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    // Animation setup
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        { y: "0" },
        {
          y: "0%",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center",
            end: "bottom bottom",
            scrub: true,
          },
        }
      );
    });

    return () => {
      if (t1) t1.kill();
    };
  }, []);

  const openModal = (title, subtext, image, location) => {
    setModalContent({ title, subtext, image, location });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Section id="roadmap">
      <Title>Landmarks</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadMapItem
            addToRef={addToRefs}
            title="Royal Building"
            subtext="Situated at the core of the Royal District, the Royal Building is a profound emblem of Apelantia's storied past and complex political landscape. Erected by the six original apes who founded the city, the building has witnessed the rise and transformation of the ruling families. It is flanked by statues of four founding apes, with the absence of two, completely removed to erase the memory of their betrayal. This venerable structure stands as a potent symbol of the enduring power and deep-seated divisions among Apelantia’s noble houses."
            image={RoyalBuilding}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Security Building"
            subtext="Located just south of the Royal Building, the Security Building is a vital stronghold where Apelantia's weapons and top government secrets are safeguarded. Under the watch of House Silverbone, it serves as both a repository for military arsenals and a vault for sensitive information, symbolizing the power and protection of the Royal government. Reinforced and imposing, the Security Building is central to maintaining order and security throughout the city."
            image={SecurityBuilding}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Amphitheater"
            subtext="The Amphitheater stands as a grand testament to Apelantia’s rich cultural heritage. Known for its striking architecture, this open-air venue hosts a variety of performances, from operas to public assemblies, drawing citizens together in celebration of the arts. Supported by the patronage of House Stillroot, it is a hub of artistic and political activity, where the vibrancy of Apelantian society echoes through its historic arcs. This venue symbolizes unity and discourse, reflecting the dynamic spirit of the city."
            image={Amphitheater}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Secret Service"
            subtext="Apelantia's Secret Service is a covert organization tasked with safeguarding national security and the royal family. Composed of elite agents from influential families, they specialize in intelligence gathering and high-stakes operations. Operating under strict confidentiality, the Secret Service manages sensitive information and preemptive actions that maintain the city’s stability. Their silent yet formidable presence plays a crucial role in managing the complex political dynamics of Apelantia."
            image={SecretService}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Bank Of Apelantia"
            subtext="The Bank of Apelantia is the financial cornerstone of the country, managing the wealth of its citizens and the fiscal policies that sustain the local economy. Known for its rigorous security and sophisticated financial services, the bank plays a crucial role in facilitating trade, investment, and economic development. It operates under the watchful eye of House Oathbash, known for their financial acumen, ensuring that economic practices within the city are both transparent and effective. The Bank of Apelantia is not just a repository of wealth, but a dynamic institution that shapes the financial health and prosperity of the entire city."
            image={BankOfApelantia}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Energy Factory"
            subtext="The Energy Factory is a pivotal yet dark element of Apelantia’s infrastructure, where electricity is generated through the torturous exploitation of prisoners. Managed by House Sullane, this facility combines technological prowess with ethical ambiguity, using brutal methods to harness energy. While powering the city, the factory also serves as a stark reminder of the extreme measures the leadership employs to maintain control and efficiency."
            image={EnergyFactory}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Royal Restaurant"
            subtext="The Royal Restaurant stands as an architectural marvel, designed as a towering stack of stone quarters, each representing a unique dining experience across its four floors. Externally, these segments are uniform, each topped with a subtly tiled roof, surrounding a central marble column that binds the diverse eateries into one cohesive structure. This restaurant is not just a place to dine; it's a celebration of gastronomic diversity, symbolizing the unity and variety of Apelantia’s culinary scene."
            image={RoyalRestaurant}
            location="Royal"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Commission HQ"
            subtext="Commission HQ stands as the epicenter of Mafia operations within Apelantia, owned and operated by select elite dons. This fortified stronghold is where crucial decisions are made, shaping the syndicate's influence across the city. The atmosphere inside is charged with strategic plotting, as leaders orchestrate both covert actions and overt power plays. More than just a base of operations, Commission HQ is a symbol of the Mafia’s pervasive control and a testament to the elite dons' grip on the city’s underworld and beyond."
            image={CommissionHQ}
            location="Mafia"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Mafia Cafe"
            subtext="Mafia Cafe is a notorious two-floor venue revered for its exclusivity and strict rule: no blood shall be shed within its walls. This code transforms the cafe into a safe haven for high-ranking apes to conduct business securely. Violating this rule results in a district-wide blacklist and a bounty on the violator's head, making it a preferred gathering place for the Mafia’s elite."
            image={MafiaCafe}
            location="Mafia"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Military Ground"
            subtext="The Military Ground, previously under Royal control, was seized by force by the Mafia, marking a significant shift in power within Apelantia. This aggressive takeover allowed the Mafia to establish their own district, asserting their dominance and strategic capabilities. In the aftermath, to avoid a larger conflict, the Mafia negotiated a deal with the Royals, securing their hold on the territory and preventing further warfare. This site now serves as a crucial hub for the Mafia’s military operations, symbolizing their tactical prowess and political influence in maintaining peace through strength."
            image={MilitaryGround}
            location="Mafia"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Weapon Guild"
            subtext="The Weapon Guild stands as the Mafia's proudest possession, a stark contrast to the secretive arms holdings of the Royals. This building boasts a distinctive architectural feature: a towering conical roof sheathed in black tiles, making a bold statement against the cityscape. Far from concealing their capabilities, the Mafia displays their extensive arsenal of weapons here, underscoring their openness and strength in armament. The Guild serves not only as a repository but also as a clear declaration of the Mafia's readiness and power, reflecting their unabashed approach to showcasing their military prowess."
            image={WeaponGuild}
            location="Mafia"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="VIP Homes"
            subtext="VIP Homes are the exclusive residences of the Mafia's elite, strategically located near the Military Ground. These large cottages, notable for their black exteriors and crimson roofs, feature prominent chimneys that emit smoke all day, conveying an image of warmth to outsiders. These homes symbolize the power and distinct status of the Mafia's top members, emphasizing their separation from ordinary citizens."
            image={VIPHomes}
            location="Mafia"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Town Hall"
            subtext="Located in the heart of the Rebel District, the Town Hall is a modest yet resilient beacon of freedom, constructed from reclaimed materials. It serves as both a strategic meeting place for Rebel leaders and a sanctuary for the district's citizens. Adorned with murals depicting unity and resistance, the hall symbolizes the Rebels' commitment to democratic governance and collective action. This vital center of Rebel activity is more than an administrative building; it embodies hope and the indomitable spirit of those challenging the status quo, with decisions shaped by the community’s collective voice."
            image={TownHall}
            location="Rebel"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Rebel Pub"
            subtext="The Rebel Pub, constructed from wood and adorned with flowers, herbs, and small animal skulls, serves as a vibrant center of entertainment and strategic meetings in the Rebel District. Its rustic charm and lively atmosphere make it a favorite gathering spot where Rebels relax, forge bonds, and strategize. This inviting venue not only entertains but also strengthens the community's resolve, making it essential to the Rebel cause and their continuous fight for change."
            image={RebelPub}
            location="Rebel"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="The Farms"
            subtext="The Farms in the Rebel District are a vital source of sustenance and self-sufficiency, reflecting the Rebels' commitment to independence and resilience. Spread across rolling fields, these farms are meticulously tended by community members who cultivate a variety of crops and raise livestock. This agrarian hub not only feeds the Rebel population but also serves as a training ground for sustainable practices, reinforcing the district's ethos of cooperation and environmental stewardship."
            image={TheFarms}
            location="Rebel"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Healing Tents"
            subtext="The Healing Tents stand as one of the initial constructions in the Rebel District, prioritizing essential medical care over architectural enhancement. Built with scarce resources and unchanged since their inception, these tents symbolize the Rebels' commitment to health and community support, even amidst material limitations. This enduring setup not only delivers vital care but also reinforces the Rebel spirit of resilience and resourcefulness."
            image={HealingTents}
            location="Rebel"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Rural School"
            subtext="The Rural School in the Rebel District serves as the educational cornerstone for the community, emphasizing practical knowledge and revolutionary ideals. Built from local materials and staffed by dedicated teachers, this school is a hub for learning and empowerment. It offers a curriculum that combines traditional academics with survival skills and political awareness, preparing young Rebels to thrive in a challenging environment and contribute meaningfully to their community's aspirations."
            image={RuralSchool}
            location="Rebel"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Snake River"
            subtext="Snake River, a dried-up, snake-shaped riverbed in the Rebel District, serves as a discreet conduit for undisclosed Rebel activities. Its serpentine path offers a strategic advantage, allowing for covert operations and movement of resources under the radar of adversaries. This once-vibrant river now symbolizes the Rebels' adaptability and tactical acumen, essential for their ongoing resistance efforts."
            image={SnakeRiver}
            location="Rebel"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Twin Towers"
            subtext="The Twin Towers in No Man's Land are striking and secretive structures managed by the Royals. Each tower serves a vital but undisclosed purpose, shrouded in mystery and commanding the landscape as symbols of hidden power."
            image={TwinTowers}
            location="No Man's Land"
            onOpen={openModal}
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="A House"
            image={AHouse}
            subtext="Just a house."
            location="No Man's Land"
            onOpen={openModal}
          />
        </Items>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Item Details"
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#202020",
            color: "#EBEBEB",
            maxWidth: window.innerWidth <= 768 ? "90vw" : "50vw", // Mobile width
            width: window.innerWidth <= 768 ? "90vw" : "50vw", // Mobile width
            padding: "0",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingTop: "calc(100% * 9 / 16)",
            backgroundColor: "#000",
          }}
        >
          <img
            src={modalContent.image}
            alt={modalContent.title}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "none",
              background: "none",
              color: "#EBEBEB",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
          >
            ×
          </button>
        </div>
        <div
          style={{
            padding: "20px",
            backgroundColor: "#202020",
            color: "#EBEBEB",
            overflowY: "auto",
            textAlign: "center",
          }}
        >
          <h2 style={{ margin: "0 0 10px 0", fontSize: "2rem" }}>
            {modalContent.title}
          </h2>
          <Typewriter
            options={{
              delay: 30,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  `<span style="font-size: 1rem;">${modalContent.subtext}</span>`
                )
                .start();
            }}
          />
        </div>
      </Modal>
    </Section>
  );
};

export default Roadmap;
