// components/sections/Home.js

import React, { useState, lazy, Suspense } from "react";
import "./Home.css";
import CoverImage from "../CoverImage";
import HomeBackground from "../../assets/homebackground.webp";
import ApesGif from "../../assets/apes.gif";
import TokenImage from "../../assets/tokenimage.png";
import tgIcon from "../../assets/tg.png";
import dexscreenerIcon from "../../assets/dexscreener.png";
import xIcon from "../../assets/x.png";
import meIcon from "../../assets/me.png";
import { useWallet } from "../../contexts/WalletProvider";
import useWindowSize from "react-use/lib/useWindowSize";

const ConfettiComponent = lazy(() => import("../Confetti"));
const Loading = () => <div>Loading...</div>;

const Home = () => {
  const { isConnected } = useWallet();
  const { width, height } = useWindowSize();
  const [copiedTokenCA, setCopiedTokenCA] = useState(false);
  const [copiedNFTCA, setCopiedNFTCA] = useState(false);
  const [notification, setNotification] = useState(null);
  const [notificationType, setNotificationType] = useState("info");
  const [showConfetti, setShowConfetti] = useState(false);
  const [shake, setShake] = useState(false); // Reintroduced shake state

  // Hard-coded values
  const totalMinted = 500;
  const maxSupply = 500;
  const nftContractAddress = "0xC8941f1447857D03eC319b06eb3aA6B6dC70B6C1";

  const handleCopyTokenCA = () => {
    const tokenCA = "0xF69A3e48f8d727e5839C771DBdF262b8C9a7ba17";
    navigator.clipboard
      .writeText(tokenCA)
      .then(() => {
        setCopiedTokenCA(true);
        setNotification("Token contract address copied to clipboard!");
        setNotificationType("info");

        setTimeout(() => {
          setCopiedTokenCA(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy token CA!", err);
        setNotification("Failed to copy token contract address.");
        setNotificationType("error");
      });
  };

  const handleCopyNFTCA = () => {
    navigator.clipboard
      .writeText(nftContractAddress)
      .then(() => {
        setCopiedNFTCA(true);
        setNotification("NFT contract address copied to clipboard!");
        setNotificationType("info");

        setTimeout(() => {
          setCopiedNFTCA(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy NFT CA!", err);
        setNotification("Failed to copy NFT contract address.");
        setNotificationType("error");
      });
  };

  const handleBuyClick = () => {
    window.open(
      "https://app.camelot.exchange/?token2=0xF69A3e48f8d727e5839C771DBdF262b8C9a7ba17",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleWhitepaperClick = () => {
    window.open(
      "https://apelantia.gitbook.io/apelantia",
      "_blank",
      "noopener,noreferrer"
    );
  };

  // Auto-hide notification after 3 seconds
  React.useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
        setNotificationType("info");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  // Hide confetti after 12 seconds
  React.useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 12000); // 12 seconds
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  // Handle Shake Effect on Grayed-out Mint Button
  const handleGrayedMintClick = () => {
    setShake(true);
    setNotification("Minting is currently disabled.");
    setNotificationType("info");
    setTimeout(() => {
      setShake(false);
    }, 500);
  };

  return (
    <section className="Section" id="home">
      {/* Confetti component for successful mint */}
      {showConfetti && (
        <div className="confetti-wrapper">
          <Suspense fallback={<Loading />}>
            <ConfettiComponent width={width} height={height} />
          </Suspense>
        </div>
      )}
      <div
        className="BackgroundImage"
        style={{ backgroundImage: `url(${HomeBackground})` }}
      />
      <div className="Container">
        <div className="Box ImageBox">
          <CoverImage />
        </div>
        <div className="Box RoundedBox">
          {/* Top-centered main title */}
          <div className="MainTopText">
            APELANTIA: FIRST-EVER AI POWERED NFT
          </div>

          <div className="SidesContainer">
            {/* Left Side */}
            <div className="Side LeftSide">
              <div className="TopText">Phase 1: Complete</div>
              <div className="Circle">
                <img src={ApesGif} alt="Animated Apes" className="CircleGif" />
              </div>
              <div className="MintControls">
                <button
                  className={`MintButton gray ${shake ? "shake" : ""}`}
                  onClick={handleGrayedMintClick}
                >
                  MINT
                </button>
                {/* NFT Copy CA Button */}
                <button
                  className="CopyCAButton"
                  onClick={handleCopyNFTCA}
                  title={nftContractAddress}
                >
                  {copiedNFTCA ? "COPIED" : "COPY CA"}
                </button>
              </div>
              <div className="TotalMinted">
                Total Minted: {totalMinted} / {maxSupply}
              </div>
            </div>
            {/* Vertical Divider */}
            <div className="VerticalDivider"></div>
            {/* Right Side */}
            <div className="Side RightSide">
              <div className="TopText">$APES IS LIVE!</div>
              <div className="Circle">
                <img src={TokenImage} alt="Token" className="CircleGif" />
              </div>
              <div className="BuyControls">
                <button className="BuyButton" onClick={handleBuyClick}>
                  BUY $APES
                </button>
                <button
                  className="CopyCAButton"
                  onClick={handleCopyTokenCA}
                  title="0xF69A3e48f8d727e5839C771DBdF262b8C9a7ba17"
                >
                  {copiedTokenCA ? "COPIED" : "COPY CA"}
                </button>
              </div>
            </div>
          </div>
          {/* Whitepaper button outside the SidesContainer */}
          <button className="WhitepaperButton" onClick={handleWhitepaperClick}>
            WHITEPAPER
          </button>
          <div className="SocialsContainer">
            <a
              href="https://t.me/apelantia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="Icon">
                <img src={tgIcon} alt="Telegram" className="IconImg" />
              </div>
            </a>
            <a
              href="https://dexscreener.com/apechain/0xebaf573c51a66c41dcef1d8363412eac8607c2eb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="Icon">
                <img
                  src={dexscreenerIcon}
                  alt="Dexscreener"
                  className="IconImg"
                />
              </div>
            </a>
            <a
              href="https://x.com/apelantia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="Icon">
                <img src={xIcon} alt="Twitter" className="IconImg" />
              </div>
            </a>
            <a
              href="https://magiceden.io/collections/apechain/0xc8941f1447857d03ec319b06eb3aa6b6dc70b6c1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="Icon">
                <img src={meIcon} alt="Magic Eden" className="IconImg" />
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* Notification modal for alerts */}
      {notification && (
        <div className={`NotificationModal ${notificationType}`}>
          <div className="NotificationContent">
            <span>{notification}</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default Home;
