// contexts/WalletProvider.js

import React, { createContext, useContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import eventEmitter from "../utils/eventEmitter";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Handle wallet connection
  const handleWalletConnection = async (accounts) => {
    if (accounts.length === 0) {
      handleWalletDisconnection();
    } else {
      const address = accounts[0];
      console.log("WalletProvider - Wallet connected:", address);
      setWalletAddress(address);
      setIsConnected(true);
      eventEmitter.emit("walletConnected", address);
    }
  };

  // Handle wallet disconnection
  const handleWalletDisconnection = () => {
    console.log("WalletProvider - Wallet disconnected");
    setWalletAddress(null);
    setIsConnected(false);
    eventEmitter.emit("walletDisconnected");
  };

  useEffect(() => {
    if (window.ethereum) {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(ethProvider);

      // Define the event handler
      const handleAccountsChangedEvent = (accounts) => {
        handleWalletConnection(accounts);
      };

      // Listen for account changes
      window.ethereum.on("accountsChanged", handleAccountsChangedEvent);

      // Check if already connected
      window.ethereum
        .request({ method: "eth_accounts" })
        .then(handleWalletConnection)
        .catch((error) => {
          console.error(
            "WalletProvider - Error checking wallet connection:",
            error
          );
        });

      // Cleanup on unmount
      return () => {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChangedEvent // Corrected function name
        );
      };
    } else {
      console.warn("WalletProvider - No Ethereum provider found");
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{
        walletAddress,
        setWalletAddress,
        provider,
        isConnected,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
