// App.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Navigation from "./components/Navigation";
import Home from "./components/sections/Home";
import HappyBirthday from "./components/sections/HappyBirthday";
import Team from "./components/sections/Team";
import ApesPage from "./components/sections/ApesPage";
import MapPage from "./components/sections/MapPage";
import NovelPage from "./components/sections/Novel";
import Play from "./components/sections/PlayPage";
import NFTDetails from "./components/sections/NFTDetails";
import FamilyPage from "./components/sections/FamilyPage";
import FactionPage from "./components/sections/FactionPage"; // Import FactionPage
import ScrollToTop from "./components/ScrollToTop";
import Sound from "./components/Sound";

const App = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const [isPlaying, setIsPlaying] = useState(false);

  const startMusic = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
  };

  return (
    <ThemeProvider theme={light}>
      <GlobalStyles />
      <Navigation startMusic={startMusic} isPlaying={isPlaying} />
      <Sound isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
      {isHomePage && (
        <>
          <Home />
          <HappyBirthday />
          <Team />
        </>
      )}
      <Routes>
        <Route path="/play" element={<Play />} />
        <Route path="/profile" element={<ApesPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/novel" element={<NovelPage />} />
        <Route path="/profile/:id" element={<NFTDetails />} />
        <Route path="/family/:familyName" element={<FamilyPage />} />
        <Route path="/faction/:factionName" element={<FactionPage />} />{" "}
        {/* Route for faction pages */}
        <Route path="/" element={<></>} />
      </Routes>
      <ScrollToTop />
    </ThemeProvider>
  );
};

export default App;
