// FamilyCard.js
import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import ApePlaceholder from "../assets/ApePlaceholder.webp";
import "./FamilyCard.css";

const FamilyCard = forwardRef(
  ({ familyName, familyMembers, familyFaction }, ref) => {
    const navigate = useNavigate();

    const getFactionColor = (faction) => {
      switch (faction?.toLowerCase()) {
        case "mafia":
          return "darkred";
        case "rebel":
          return "green";
        case "royal":
          return "gold";
        default:
          return "#EBEBEB";
      }
    };

    const handleClick = () => {
      navigate(`/family/${familyName}`);
    };

    // Ensure there are always 9 images
    const displayedMembers = familyMembers.slice(0, 9);
    const placeholdersNeeded = 9 - displayedMembers.length;
    const placeholders = Array(placeholdersNeeded).fill(null);

    return (
      <div className="family-card" onClick={handleClick} ref={ref}>
        <div className="family-card-header">
          <h2 className="family-name">{familyName}</h2>
          <p>
            Faction:{" "}
            <span style={{ color: getFactionColor(familyFaction) }}>
              {familyFaction}
            </span>
          </p>
          <p>Members: {familyMembers.length}</p>
        </div>
        <div className="family-images">
          {displayedMembers.map((ape) => (
            <img
              key={ape.id}
              src={`https://storage.googleapis.com/apelantia/pixelated/${ape.id}.png`}
              alt={ape.name}
              className="family-ape-image"
            />
          ))}
          {placeholders.map((_, index) => (
            <img
              key={`placeholder-${index}`}
              src={ApePlaceholder}
              alt="Placeholder"
              className="family-ape-image placeholder"
            />
          ))}
        </div>
      </div>
    );
  }
);

export default FamilyCard;
