import React, { useEffect } from "react";
import "./ConnectWallet.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiConfig } from "wagmi";
import {
  RainbowKitProvider,
  getDefaultConfig,
  ConnectButton,
} from "@rainbow-me/rainbowkit";
import { useWallet } from "../contexts/WalletProvider";
import merge from "lodash.merge";
import "@rainbow-me/rainbowkit/styles.css";

const queryClient = new QueryClient();

const apeChain = {
  id: 33139,
  name: "ApeChain",
  network: "ApeChain",
  nativeCurrency: {
    decimals: 18,
    name: "APE",
    symbol: "APE",
  },
  rpcUrls: {
    default: { http: ["https://apechain.calderachain.xyz/http"] },
  },
  blockExplorers: {
    default: {
      name: "ApechainExplorer",
      url: "https://apechain.calderaexplorer.xyz/",
    },
  },
};

const colors = {
  mainColor: "#1447f2", // var(--main-color)
  secondaryColor: "#1c3a83", // var(--secondary-color)
  accentColor: "#ebebeb", // var(--accent-color)
  lightColor: "#f0f4ff", // var(--light-color)
  darkColor: "#202020", // var(--dark-color)
};

const baseTheme = getDefaultConfig({
  appName: "RainbowKit demo",
  projectId: "2d210143252cfdb8e350ebf7bf30051c",
  chains: [apeChain],
  ssr: false,
});

const customTheme = merge(baseTheme.theme, {
  colors: {
    accentColor: colors.mainColor,
    accentColorForeground: colors.accentColor,
    actionButtonBorder: colors.accentColor,
    actionButtonBorderMobile: colors.accentColor,
    actionButtonSecondaryBackground: colors.darkColor,
    closeButton: colors.darkColor,
    closeButtonBackground: colors.mainColor,
    connectButtonBackground: colors.mainColor,
    connectButtonBackgroundError: colors.mainColor,
    connectButtonInnerBackground: colors.darkColor,
    connectButtonText: colors.accentColor,
    connectButtonTextError: colors.accentColor,
    connectionIndicator: colors.darkColor,
    generalBorder: colors.accentColor,
    generalBorderDim: colors.accentColor,
    menuItemBackground: colors.darkColor,
    modalBackdrop: "rgba(0,0,0,0.5)",
    modalBackground: colors.darkColor,
    modalBorder: colors.accentColor,
    modalText: colors.accentColor,
    modalTextDim: colors.accentColor,
    modalTextSecondary: colors.mainColor,
    profileAction: colors.mainColor,
    profileActionHover: colors.darkColor,
    profileForeground: colors.mainColor,
    selectedOptionBorder: colors.accentColor,
    standby: colors.accentColor,
  },
  fonts: {
    body: '"Poppins", sans-serif',
  },
  radii: {
    actionButton: "10px",
    connectButton: "10px",
    menuButton: "10px",
    modal: "20px",
    modalMobile: "20px",
  },
  shadows: {
    connectButton: `0 0 0 2px ${colors.accentColor}`,
    dialog: `0 0 0 2px ${colors.accentColor}`,
    profileDetailsAction: `0 0 0 2px ${colors.accentColor}`,
    selectedOption: `0 0 0 2px ${colors.accentColor}`,
    selectedWallet: `0 0 0 2px ${colors.accentColor}`,
    walletLogo: `0 0 0 2px ${colors.accentColor}`,
  },
});

function ConnectWallet() {
  const { walletAddress, setWalletAddress } = useWallet();

  const handleWalletConnection = (address) => {
    console.log("ConnectWallet.js - Wallet connected:", address);
    setWalletAddress(address);
  };

  const handleWalletDisconnection = () => {
    console.log("ConnectWallet.js - Wallet disconnected");
    setWalletAddress(null);
  };

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      console.log("ConnectWallet.js - accountsChanged event:", accounts);
      if (accounts.length > 0) {
        handleWalletConnection(accounts[0]);
      } else {
        handleWalletDisconnection();
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, []);

  return (
    <WagmiConfig config={baseTheme}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={customTheme} chains={[apeChain]}>
          <div className="connect-wallet">
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button onClick={openConnectModal} type="button">
                            Connect Wallet
                          </button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button onClick={openChainModal} type="button">
                            Wrong network
                          </button>
                        );
                      }

                      return (
                        <div style={{ display: "flex", gap: "10px" }}>
                          <button
                            onClick={openChainModal}
                            className="chain-button"
                            type="button"
                          >
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 30,
                                  height: 30,
                                  borderRadius: 10,
                                  overflow: "hidden",
                                  marginRight: 10,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? "Chain icon"}
                                    src={chain.iconUrl}
                                    style={{ width: 30, height: 30 }}
                                  />
                                )}
                              </div>
                            )}
                            {chain.name}
                          </button>

                          <button
                            onClick={openAccountModal}
                            className="account-button"
                            type="button"
                          >
                            {account.displayName}
                          </button>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}

export default ConnectWallet;
