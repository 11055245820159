// FamilyList.js
import React from "react";
import FamilyCard from "./FamilyCard";
import "./FamilyList.css";

const FamilyList = ({ families, lastFamilyElementRef }) => {
  return (
    <div className="family-list-container">
      {families.map((family, index) => {
        if (index === families.length - 1) {
          // Attach ref to the last item for infinite scroll
          return (
            <FamilyCard
              key={family.familyName}
              familyName={family.familyName}
              familyMembers={family.familyMembers}
              familyFaction={family.familyFaction}
              ref={lastFamilyElementRef}
            />
          );
        } else {
          return (
            <FamilyCard
              key={family.familyName}
              familyName={family.familyName}
              familyMembers={family.familyMembers}
              familyFaction={family.familyFaction}
            />
          );
        }
      })}
    </div>
  );
};

export default FamilyList;
