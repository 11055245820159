import React from "react";
import { useNavigate } from "react-router-dom";
import apesData from "../../Encrypted.json"; // Import the JSON data directly
import "./HappyBirthday.css";

const HappyBirthday = () => {
  const navigate = useNavigate(); // Initialize useNavigate for redirection
  const today = new Date();
  const todayDateString = `${String(today.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(today.getDate()).padStart(2, "0")}`;

  const birthdayApesToday = apesData
    .filter((ape) => {
      const apeBirthDate = new Date(ape.birthdate);
      const apeDateString = `${String(apeBirthDate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(apeBirthDate.getDate()).padStart(2, "0")}`;

      return apeDateString === todayDateString;
    })
    .map((ape) => {
      const birthYear = new Date(ape.birthdate).getFullYear();
      const age = today.getFullYear() - birthYear;
      return { ...ape, age };
    });

  if (birthdayApesToday.length === 0) {
    return (
      <div className="happy-birthday-container">
        <h2>No Apes have birthdays today!</h2>
      </div>
    );
  }

  const handleCardClick = (id) => {
    navigate(`/profile/${id}`); // Redirect to the profile page with the corresponding id
  };

  return (
    <div className="happy-birthday-container">
      <h2>🎉 Happy Birthday, Apelantians! 🎉</h2>
      <div
        className={`birthday-apes-grid apes-count-${birthdayApesToday.length}`}
      >
        {birthdayApesToday.map((ape) => {
          let factionColor;
          switch (ape.faction?.toLowerCase()) {
            case "mafia":
              factionColor = "darkred";
              break;
            case "rebel":
              factionColor = "green";
              break;
            case "royal":
              factionColor = "gold";
              break;
            default:
              factionColor = "#EBEBEB";
              break;
          }

          return (
            <div
              key={ape.id}
              className="birthday-ape-card"
              onClick={() => handleCardClick(ape.id)}
            >
              <img
                src={`https://storage.googleapis.com/apelantia/pixelated/${ape.id}.png`}
                alt={ape.name}
                className="ape-image"
              />
              <h3>{ape.name.split("#")[0].trim()}</h3>
              <p className="ape-age">Turned {ape.age}!</p>
              <p className="ape-faction" style={{ color: factionColor }}>
                Faction: {ape.faction || "Unknown"}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HappyBirthday;
