// NFTList.js
import React from "react";
import NFTCard from "./NFTCard";
import "./NFTList.css";

const NFTList = ({ nftCardsData, lastNFTElementRef }) => {
  return (
    <div className="nft-list-container">
      {nftCardsData.map((card, index) => {
        if (index === nftCardsData.length - 1 && lastNFTElementRef) {
          // Attach ref to the last item for infinite scrolling
          return (
            <NFTCard
              key={card.id}
              imageUrl={card.imageUrl}
              name={card.name}
              id={card.id}
              age={card.age}
              faction={card.faction}
              role={card.role}
              ref={lastNFTElementRef}
            />
          );
        } else {
          return (
            <NFTCard
              key={card.id}
              imageUrl={card.imageUrl}
              name={card.name}
              id={card.id}
              age={card.age}
              faction={card.faction}
              role={card.role}
            />
          );
        }
      })}
    </div>
  );
};

export default NFTList;
