// NFTCard.js
import React, { useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import "./NFTCard.css";

const NFTCard = forwardRef(
  ({ imageUrl, name, id, age, faction, role }, ref) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const getFactionColor = (faction) => {
      switch (faction?.toLowerCase()) {
        case "mafia":
          return "darkred";
        case "rebel":
          return "green";
        case "royal":
          return "gold";
        case "genesis":
          return "purple"; // Added new faction color
        default:
          return "#EBEBEB"; // Default color if faction is not recognized
      }
    };

    return (
      <Link to={`/profile/${id}`} style={{ textDecoration: "none" }} ref={ref}>
        <div className="nft-card">
          {!imageLoaded && <div className="image-placeholder2">Loading...</div>}
          <img
            src={imageUrl}
            alt={name}
            className={`nft-image ${imageLoaded ? "" : "hidden"}`}
            onLoad={() => setImageLoaded(true)}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
          <div className="nft-info">
            <h1 className="nft-name">{name}</h1>
            <p>
              Faction:{" "}
              <span style={{ color: getFactionColor(faction) }}>{faction}</span>
            </p>
            <p>Role: {role}</p>
            <p>Age: {age}</p>
            <p>ID: {id}</p> {/* Display the actual ID */}
          </div>
        </div>
      </Link>
    );
  }
);

export default NFTCard;
