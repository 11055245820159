// AssetModal.js
import React, { useState } from "react";
import "./AssetModal.css";

const factions = ["Mafia", "Rebel", "Royal"]; // Define factions

const AssetModal = ({
  assetsList,
  selectedAssets,
  setSelectedAssets,
  onClose,
  selectedFaction,
  setSelectedFaction,
  setSortBy, // Accept setSortBy as a prop
}) => {
  // Extract unique trait types from the assetsList
  const traitTypes = [
    ...new Set(assetsList.map((item) => item.split(" - ")[0])),
  ];

  const [activeTraitType, setActiveTraitType] = useState(null);

  // Filter the values for the active trait type
  const filteredValues = activeTraitType
    ? assetsList.filter((item) => item.startsWith(activeTraitType))
    : [];

  const handleTraitTypeClick = (traitType) => {
    setActiveTraitType(traitType === activeTraitType ? null : traitType);
  };

  const handleValueClick = (traitType, value) => {
    const updatedSelectedAssets = { ...selectedAssets };
    if (updatedSelectedAssets[traitType] === value) {
      delete updatedSelectedAssets[traitType];
    } else {
      updatedSelectedAssets[traitType] = value;
    }
    setSelectedAssets(updatedSelectedAssets);
  };

  const handleFactionClick = (faction) => {
    const newFaction = faction === selectedFaction ? null : faction;
    setSelectedFaction(newFaction);

    // Directly update the sortBy state in ApesPage when the faction is selected
    setSortBy(newFaction || "All");
  };

  const isValueSelected = (traitType, value) => {
    return selectedAssets[traitType] === value;
  };

  const handleReset = () => {
    setSelectedAssets({});
    setSelectedFaction(null);
    setActiveTraitType(null);
    setSortBy("All"); // Reset sortBy to "All" on reset
  };

  return (
    <div className="asset-modal-overlay" onClick={onClose}>
      <div className="asset-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="asset-modal-header">
          <h2>Select Assets</h2>
          <button className="asset-modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="asset-modal-body">
          <div className="faction-types">
            <h4>Faction</h4>
            {factions.map((faction) => (
              <div
                key={faction}
                className={`faction ${
                  selectedFaction === faction ? "active" : ""
                }`}
                onClick={() => handleFactionClick(faction)}
              >
                {faction}
              </div>
            ))}
          </div>
          <div className="trait-types">
            <h4>Traits</h4>
            {traitTypes.map((traitType) => (
              <div
                key={traitType}
                className={`trait-type ${
                  activeTraitType === traitType ? "active" : ""
                }`}
                onClick={() => handleTraitTypeClick(traitType)}
              >
                {traitType}
              </div>
            ))}
          </div>
          {activeTraitType && (
            <div className="trait-values">
              {filteredValues.map((item) => {
                const value = item.split(" - ")[1];
                return (
                  <div
                    key={value}
                    className={`trait-value ${
                      isValueSelected(activeTraitType, value) ? "selected" : ""
                    }`}
                    onClick={() => handleValueClick(activeTraitType, value)}
                  >
                    {value}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="asset-modal-footer">
          <button className="asset-modal-reset" onClick={handleReset}>
            Reset
          </button>
          <h4>Selected Assets:</h4>
          <div className="selected-assets">
            {selectedFaction && (
              <div className="selected-asset">Faction: {selectedFaction}</div>
            )}
            {Object.entries(selectedAssets).map(([traitType, value]) => (
              <div key={traitType} className="selected-asset">
                {traitType}: {value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetModal;
