// FamilyPage.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apesData from "../../Encrypted.json";
import NFTList from "../NFTList"; // Import NFTList
import calculateAge from "../../utils/calculateAge"; // Import calculateAge
import "./FamilyPage.css";

const FamilyPage = () => {
  const { familyName } = useParams();
  const [familyMembers, setFamilyMembers] = useState([]);

  useEffect(() => {
    const members = apesData.filter((ape) => {
      const nameParts = ape.name.split(" ");
      const lastName = nameParts[nameParts.length - 1];
      return lastName === familyName;
    });

    // Map data to match NFTCard requirements
    const nftCardsData = members.map((nft) => ({
      imageUrl: `https://storage.googleapis.com/apelantia/pixelated/${nft.id}.png`,
      name: nft.name,
      id: nft.id,
      age: calculateAge(nft.birthdate),
      faction: nft.faction,
      role: nft.role,
    }));

    setFamilyMembers(nftCardsData);
  }, [familyName]);

  if (familyMembers.length === 0) {
    return <div>No family members found for {familyName}</div>;
  }

  return (
    <div className="family-page-container">
      <h1 className="family-page-title">{familyName} Family</h1>
      <NFTList nftCardsData={familyMembers} />
    </div>
  );
};

export default FamilyPage;
