// Families.js
import React, { useState, useEffect } from "react";
import apesData from "../../Encrypted.json";
import FamilyList from "../FamilyList";
import "./Families.css";

const Families = () => {
  const [families, setFamilies] = useState([]);
  const [filteredFamilies, setFilteredFamilies] = useState([]);
  const [selectedFaction, setSelectedFaction] = useState(null);

  useEffect(() => {
    // Group apes by family name
    const familyMap = new Map();

    apesData.forEach((ape) => {
      const nameParts = ape.name.split(" ");
      const lastName = nameParts[nameParts.length - 1];

      if (!familyMap.has(lastName)) {
        familyMap.set(lastName, []);
      }
      familyMap.get(lastName).push(ape);
    });

    const familiesArray = Array.from(familyMap.entries()).map(
      ([familyName, members]) => {
        const familyFaction = members[0].faction;
        return {
          familyName,
          familyMembers: members,
          familyFaction,
        };
      }
    );

    setFamilies(familiesArray);
    setFilteredFamilies(familiesArray);
  }, []);

  const handleFactionClick = (faction) => {
    if (faction === selectedFaction) {
      // Reset to show all families and clear selection
      setSelectedFaction(null);
      setFilteredFamilies(families);
    } else {
      // Filter families by the selected faction and mark as active
      setSelectedFaction(faction);
      const filtered = families.filter(
        (family) => family.familyFaction.toLowerCase() === faction.toLowerCase()
      );
      setFilteredFamilies(filtered);
    }
  };

  return (
    <div className="families-page-container">
      <div className="faction-buttons">
        {["Mafia", "Rebel", "Royal"].map((faction) => (
          <button
            key={faction}
            className={`faction-button ${
              selectedFaction === faction
                ? "active"
                : selectedFaction
                ? "inactive"
                : ""
            } ${faction.toLowerCase()}`}
            onClick={() => handleFactionClick(faction)}
          >
            {faction}
          </button>
        ))}
      </div>
      <FamilyList families={filteredFamilies} />
    </div>
  );
};

export default Families;
