// NFTDetails.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import apesData from "../../Decrypted.json"; // Updated import
import images from "../../utils/ImportImages"; // Import the images from the utility file
import "./NFTDetails.css";
import { ReactComponent as LeftArrowIcon } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/right-arrow.svg";
import Footer from "../Footer";
import calculateAge from "../../utils/calculateAge"; // Assuming you have this utility
import { CONTRACT_ADDRESS, CONTRACT_ABI } from "../../utils/contract";
import { ethers } from "ethers";

const NFTDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [nft, setNft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Utility function to parse relative strings into objects with name and id
  const parseRelative = (relativeStr) => {
    const regex = /^(.+?)#(\d+)$/;
    const match = relativeStr.match(regex);
    if (match) {
      return {
        name: match[1],
        id: match[2],
      };
    }
    // If the format is unexpected, return the whole string as name and null id
    return {
      name: relativeStr,
      id: null,
    };
  };

  useEffect(() => {
    const fetchNFTData = async () => {
      setNft(null);
      setError(null);
      setLoading(true);

      try {
        const numericId = Number(id);
        if (isNaN(numericId)) {
          setError("Invalid NFT ID");
          setLoading(false);
          return;
        }

        // Find the NFT data
        const apeData = apesData.find((ape) => Number(ape.id) === numericId);

        if (!apeData) {
          setError("NFT not found");
          setLoading(false);
          return;
        }

        // Use the Google Cloud Storage link directly
        const imageUrl = `https://storage.googleapis.com/apelantia/apelantians/${apeData.id}.png`;

        // Process relatives array to extract name and id, and assign image URLs
        let processedRelatives = [];
        if (Array.isArray(apeData.relatives) && apeData.relatives.length > 0) {
          processedRelatives = apeData.relatives.map((relativeStr) => {
            const relative = parseRelative(relativeStr);
            const relativeImageUrl = relative.id
              ? images[relative.id]
                ? images[relative.id]
                : `https://storage.googleapis.com/apelantia/apelantians/${relative.id}.png` // Updated image URL
              : null;

            return {
              ...relative,
              image: relativeImageUrl,
            };
          });
        }

        // Set the state with the final data, removing #ID from name
        setNft({
          ...apeData,
          name: apeData.name.replace(/ #\d+$/, ""), // Remove the #ID suffix
          image: imageUrl,
          relatives: processedRelatives,
          age: calculateAge(apeData.birthdate),
        });
        setLoading(false);
      } catch (err) {
        console.error(`Error fetching NFT data: ${err.message}`);
        setError(`Error fetching NFT data: ${err.message}`);
        setLoading(false);
      }
    };

    fetchNFTData();
  }, [id]);

  const handleNavigation = (direction) => {
    const currentIndex = apesData.findIndex(
      (ape) => Number(ape.id) === Number(id)
    );
    if (currentIndex !== -1) {
      const newIndex =
        direction === "left"
          ? (currentIndex - 1 + apesData.length) % apesData.length
          : (currentIndex + 1) % apesData.length;
      const newId = apesData[newIndex].id;
      navigate(`/profile/${newId}`);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handleNavigation("left");
      } else if (event.key === "ArrowRight") {
        handleNavigation("right");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [id]);

  const LoadingPlaceholder = ({ text }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "50%",
        fontSize: "24px",
        color: "#202020",
        fontFamily: "Poppins, monospace",
        backgroundColor: "#ccc",
        textAlign: "center",
        position: "relative",
      }}
    >
      {text}
    </div>
  );

  if (loading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#202020",
          fontSize: "24px",
          fontFamily: "Poppins, monospace",
        }}
      >
        {error}
      </div>
    );
  }

  if (!nft) return <p>No NFT data found.</p>;

  const getFactionColor = (faction) => {
    switch (faction?.toLowerCase()) {
      case "mafia":
        return "darkred";
      case "rebel":
        return "green";
      case "royal":
        return "gold";
      case "genesis":
        return "purple"; // Added new faction color
      default:
        return "#EBEBEB";
    }
  };

  const factionColor = getFactionColor(nft.faction);

  return (
    <div style={{ fontFamily: "Poppins, sans-serif" }} key={id}>
      <div className="nft-details-container">
        <div className="nft-image-container">
          {nft.image ? (
            <img src={nft.image} alt={nft.name} className="nft-image" />
          ) : (
            <LoadingPlaceholder text={nft.name || "Loading NFT..."} />
          )}
        </div>

        <div className="nft-info-container">
          <div className="nft-info-header">
            <div className="nft-info-section">
              <h2>Name: {nft.name}</h2>
              <h2>ID: {nft.id}</h2> {/* Display the actual ID */}
              <h2>
                Faction:{" "}
                <span style={{ color: factionColor, fontSize: "1em" }}>
                  {nft.faction}
                </span>
              </h2>
            </div>
            <div className="nft-info-section">
              <h2>Role: {nft.role}</h2>
              <h2>Birthdate: {nft.birthdate}</h2>
              <h2>Age: {nft.age}</h2> {/* Display calculated age */}
            </div>
          </div>

          <div className="nft-section-title">
            <div className="navigation-arrows">
              <div
                className="navigation-link"
                onClick={() => handleNavigation("left")}
              >
                <LeftArrowIcon className="arrow-icon" />
              </div>
              <h2>Description</h2>
              <div
                className="navigation-link"
                onClick={() => handleNavigation("right")}
              >
                <RightArrowIcon className="arrow-icon" />
              </div>
            </div>
          </div>
          <div className="typewriter-container">
            {nft.description ? (
              <Typewriter
                options={{
                  strings: nft.description,
                  autoStart: true,
                  delay: 20,
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="nft-attributes-siblings">
            <div className="nft-attributes">
              <h2 className="nft-section-title">Attributes</h2>
              <ul>
                {nft.attributes
                  ? nft.attributes
                      .filter(
                        (attribute) =>
                          attribute.display_type !== "date" &&
                          attribute.trait_type.toLowerCase() !== "birthday"
                      )
                      .map((attribute, index) => (
                        <li key={index}>
                          {attribute.trait_type}: {attribute.value}
                        </li>
                      ))
                  : null}
              </ul>
            </div>
            <div className="nft-siblings">
              <h2 className="nft-section-title">Relatives</h2>
              <ul>
                {nft.relatives && nft.relatives.length > 0 ? (
                  nft.relatives.map((relative) => (
                    <li
                      key={relative.id || relative.name} // Fallback to name if id is null
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {relative.image ? (
                        <img
                          src={relative.image}
                          alt={`Relative ${relative.name}`}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "10px",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#ccc",
                            marginRight: "10px",
                          }}
                        />
                      )}
                      {relative.id ? (
                        <Link to={`/profile/${relative.id}`}>
                          {relative.name}
                        </Link>
                      ) : (
                        relative.name
                      )}
                    </li>
                  ))
                ) : (
                  <li>No relatives found.</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NFTDetails;
